import React from "react"
import { Container } from "react-bootstrap"
import "./StatOffplan.scss"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const StatOffplan = ({ data }) => {
  if (!data?.icon_list?.length) return
  return (
    <div className="offplan_haus_stat">
      <Container>
        <div className="list_stat_sections">
          {data.icon_list.map(list => {
            return (
              <div className="list_values">
                <img src={list?.image?.url} />
                <h3>{list?.heading}</h3>
                {list?.sub_content?.data?.sub_content && (
                  <ContentModule
                    Content={list?.sub_content?.data?.sub_content}
                  />
                )}
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default StatOffplan