import React, { useState } from "react"
import { Container } from "react-bootstrap"
import GGFXImage from "../../modules/GGFXImage"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./OffplanCampaignCards.scss"
import PlayVideo from "../PlayVideo/PlayVideo"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")

const OffplanCampaignCards = ({ data, offplanData }) => {
  const [isPlay, setPlay] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")

  const handleVideo = item => {
    setPlay(true)
    setVideoUrl(item?.video_url)
  }
  const { isMobile } = useDeviceMedia()
  const imageName = "off-plan-campaign.primary_module_items_image.card_image"
  if (!data?.items?.length) return

  return (
    <div className="offplan-campaign-cards">
      <Container>
        <div className="offplancampaign-card-module">
          <h2>{data?.title}</h2>
          <div className="offplancard-desktop-wrap d-none d-xl-grid">
            {data?.items?.map(item => {
              return (
                <div className="item_Card-list">
                  <div className="image-section">
                    <GGFXImage
                      ImageSrc={item?.image}
                      altText={`${
                        item?.image?.alternativeText
                          ? item?.image?.alternativeText
                          : item.title
                      }`}
                      imagetransforms={offplanData?.ggfx_results}
                      renderer="srcSet"
                      imagename={imageName}
                      strapiID={offplanData?.strapi_id}
                    />

                    {item?.video_url && (
                      <button
                        onClick={() => handleVideo(item)}
                        className="play-button"
                      >
                        <i className="icon video-filled-icon" />
                      </button>
                    )}
                  </div>

                  <div className="content-section">
                    <h5 className="title">{item?.title}</h5>
                    {item?.sub_title && 
                    <p className="address">
                      <i className="icon map-icon" />
                      {item?.sub_title}
                    </p>
                    }
                    {item?.content?.data?.content && (
                      <div className="description">
                        <ContentModule Content={item?.content?.data?.content} />
                      </div>
                    )}
                    {item?.cta?.title &&
                    <PreviewCTALink
                      class="button button-filled-green"
                      link={
                        item?.cta?.link
                          ? item?.cta?.link
                          : { external_link: item?.cta?.custom_link }
                      }
                      title={item?.cta?.title}
                      target_window={
                        item?.cta?.custom_link?.includes("http")
                          ? "new_window"
                          : item?.cta?.target_window
                      }
                    />
                    }
                  </div>
                </div>
              )
            })}
          </div>
          <div className="offplancard-mobile_tab-wrap d-block d-xl-none">
            <CustomSlider
              noDots
              showArrows
              arrowOnMobile
              slidecount={isMobile ? 1 : 2}
              iconchange
              className="offplanNewlayout-slider slider-blocks"
            >
              {data?.items?.map(item => {
                return (
                  <div className="item_Card-list">
                    <div className="image-section">
                      <GGFXImage
                        ImageSrc={item?.image}
                        altText={`${
                          item?.image?.alternativeText
                            ? item?.image?.alternativeText
                            : item.title
                        }`}
                        imagetransforms={offplanData?.ggfx_results}
                        renderer="srcSet"
                        imagename={imageName}
                        strapiID={offplanData?.strapi_id}
                      />
                      {item?.video_url && (
                        <button
                          onClick={() => handleVideo(item)}
                          className="play-button"
                        >
                          <i className="icon video-filled-icon" />
                        </button>
                      )}
                    </div>

                    <div className="content-section">
                      <h5 className="title">{item?.title}</h5>
                      {item?.sub_title &&
                      <p className="address">
                        <i className="icon map-icon" />
                        {item?.sub_title}
                      </p>
                      }
                      {item?.content?.data?.content && (
                        <div className="description">
                          <ContentModule
                            Content={item?.content?.data?.content}
                          />
                        </div>
                      )}
                      {item?.cta?.title&&
                      <PreviewCTALink
                        class="button button-filled-green"
                        link={
                          item?.cta?.link
                            ? item?.cta?.link
                            : { external_link: item?.cta?.custom_link }
                        }
                        title={item?.cta?.title}
                        target_window={
                          item?.cta?.custom_link?.includes("http")
                            ? "new_window"
                            : item?.cta?.target_window
                        }
                      />
                        }
                    </div>
                  </div>
                )
              })}
            </CustomSlider>
          </div>
        </div>
      </Container>
      <PlayVideo
        isOpen={isPlay}
        stopPlay={setPlay}
        videoId=""
        isCloseFunction={setPlay}
        videourl={videoUrl}
        htmlink={""}
      />
    </div>
  )
}

export default OffplanCampaignCards